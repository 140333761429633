import React from 'react';
import PropTypes from 'prop-types';

function BlogCaption({ title, date, categories }) {
  return (
    <>
      <div className="block md:hidden">
        <div className="p-6 border-2 font-semiBold rounded-bl-2xl rounded-br-2xl border-primary-1">
          <div className="mb-2 text-sm uppercase font-regular">
            {categories
              ? categories.nodes.map((cat) => cat.name).join(' | ')
              : ''}
          </div>
          <div className="font-semiBold mb-2">{title}</div>
          <div className="text-sm font-regular">{date}</div>
        </div>
      </div>
      <div className="hidden md:block">
        <div className="relative p-6 border-2 font-semiBold h-44 rounded-bl-2xl rounded-br-2xl border-primary-1">
          <div className="mb-2 text-sm uppercase font-regular">
            {categories
              ? categories.nodes.map((cat) => cat.name).join(' | ')
              : ''}
          </div>
          <div className="font-semiBold">{title}</div>
          <div className="absolute text-sm font-regular bottom-6 left-6">
            {date}
          </div>
        </div>
      </div>
    </>
  );
}

BlogCaption.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  categories: PropTypes.object,
};

export default BlogCaption;
